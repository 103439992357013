import Service from "./Service";
const baseurl = "/api/historia/graficas";

export default {
  store(obj) {
    return Service.post(`${baseurl}/store`, obj);
  },
  show(id) {
    return Service.get(`${baseurl}/show/${id}`);
  },
};
